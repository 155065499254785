import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import Auth from 'components/Auth';
import Layout from 'components/Order/Layout';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { PATH_REFUND } from 'utils/constants/paths';
import CcAuth from 'components/CcAuth';

import IconArrowUp from 'images/arrow-up.svg';
import IconArrowDown from 'images/arrow-down.svg';

const Container = styled.div``;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const Card = styled.div`
  height: 717px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 26px 10px;

  @media (max-width: ${md}) {
    padding: 16px;
    height: auto;
  }
  .skeleton {
    min-height: 100px;
  }
  .skeleton-total-box {
    min-height: 320px;
    @media (max-width: ${md}) {
      min-height: 260px;
    }
  }
`;

const OrderBox = styled.div`
  width: 100%;
  padding: 12px 16px;
  background-color: rgba(95, 210, 218, 0.25);
  border: 1px solid #5fd2da;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin-bottom: 22px;

  @media (max-width: ${md}) {
    margin-bottom: 16px;
  }
`;

const OrderBoxRow = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  justify-content: space-between;
`;

const OrderNumWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 15px;

  @media (max-width: ${md}) {
    padding: 0 14px;
  }
`;

const OrderNum = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
`;

const ProductListWrapper = styled.div`
  @media (max-width: ${md}) {
    padding: 0 14px;
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  margin: 12px 0;
  max-height: ${(props) => (props.isExpand ? 'auto' : '110px')};
  overflow: hidden;

  @media (max-width: ${md}) {
    margin: 16px 0;
    max-height: ${(props) => (props.isExpand ? 'auto' : '180px')};
  }

  ${(props) =>
    props.isExpand &&
    css`
      margin-bottom: 12px;
    `}
`;

const Product = styled.div`
  display: flex;

  @media (max-width: ${md}) {
    flex-wrap: wrap;
  }
`;

const ProductCount = styled.div`
  width: 28px;
  height: 28px;
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  @media (max-width: ${md}) {
    order: 2;
  }

  &::before {
    content: '${(props) => props.count}';
  }
`;

const ProductName = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;

  @media (max-width: ${md}) {
    order: 1;
    width: 100%;
  }
`;

const ProductRefundPrice = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-left: auto;

  @media (max-width: ${md}) {
    order: 3;
  }
`;

const ExpandBtnWrapper = styled.div`
  padding: 20px 0;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;

  @media (max-width: ${md}) {
    padding: 16px 0;
  }
`;

const ExpandBtn = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  position: relative;

  &::before {
    content: '展開更多';
  }

  &::after {
    content: '';
    background-image: url(${IconArrowDown});
    background-size: cover;
    width: 11px;
    height: 6px;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.isExpand &&
    css`
      &::before {
        content: '收起';
      }

      &::after {
        content: '';
        background-image: url(${IconArrowUp});
      }
    `}
`;

const TotalRefundBox = styled.div`
  width: 100%;
  height: 59px;
  background-color: #f2f2f2;
  padding: 16px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: ${md}) {
    margin-bottom: 0;
  }
`;

const RefundBlockList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

const RefundBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 16px;
`;

const RefundBlockTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;

  @media (max-width: ${md}) {
    margin-bottom: 8px;
  }
`;

const MobileBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 108px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: ${md}) {
    display: flex;
  }
`;

const Btn = styled.div`
  width: 343px;
  height: 60px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
`;
const RENDER_BOUND_SIZE = 3;

const RefundDetail = (props) => {
  const { refundCode } = props;
  const api = useAPI();
  const pageTitle = '退訂退款詳情';

  const [refund, setRefund] = useState({});
  const [refundProducts, setRefundProducts] = useState();
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [isExpandProductList, setIsExpandProductList] = useState(false);
  const shouldRenderExpandButton = refundProducts?.length > RENDER_BOUND_SIZE;

  const expandProductList = () => {
    setIsExpandProductList((state) => !state);
  };
  const getRefundDetail = () => {
    api
      .getRefundDetail(refundCode)
      .then((res) => {
        setRefund(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    const products = refund.products;
    if (products) {
      setRefundProducts([...products.first, ...products.other_else]);
    }
  }, [refund]);
  useEffect(() => {
    if (!ccAuthIsReady) return;
    getRefundDetail();
  }, [ccAuthIsReady]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Layout {...props}>
          <Auth />
          <Container>
            <TitleBar>
              <Title>{pageTitle}</Title>
            </TitleBar>
            <Card>
              {Object.keys(refund).length > 0 ? (
                <>
                  <OrderBox>
                    <OrderBoxRow>
                      <div>訂單編號</div>
                      <div>{refund.code}</div>
                    </OrderBoxRow>
                    <OrderBoxRow>
                      <div>{`${refund.apply_refund_date} ${refund.apply_refund_time}`}</div>
                      <div>{refund.refund_status}</div>
                    </OrderBoxRow>
                  </OrderBox>
                  <OrderNumWrapper>
                    <div>訂單編號</div>
                    <OrderNum>{refund.order_no}</OrderNum>
                  </OrderNumWrapper>
                </>
              ) : (
                <>
                  <Skeleton className="skeleton" />
                  <OrderNumWrapper />
                </>
              )}

              <ProductListWrapper>
                <ProductList isExpand={isExpandProductList}>
                  {refundProducts !== undefined ? (
                    <>
                      {refundProducts?.map((product) => {
                        return (
                          <Product>
                            <ProductCount count={product.qty} />
                            <ProductName>{product.item_name}</ProductName>
                            <ProductRefundPrice>
                              退訂金額 ${product.subtotal}
                            </ProductRefundPrice>
                          </Product>
                        );
                      })}
                    </>
                  ) : (
                    <Skeleton className="skeleton" />
                  )}
                </ProductList>
                {shouldRenderExpandButton && (
                  <ExpandBtnWrapper>
                    <ExpandBtn
                      isExpand={isExpandProductList}
                      onClick={expandProductList}
                    />
                  </ExpandBtnWrapper>
                )}
              </ProductListWrapper>
              {Object.keys(refund).length > 0 ? (
                <>
                  <TotalRefundBox>
                    <div>總計已退款</div>
                    <div>${refund.refund_amount}</div>
                  </TotalRefundBox>
                  <RefundBlockList>
                    <RefundBlock>
                      <RefundBlockTitle>退訂資料</RefundBlockTitle>
                      <div>退款方式：{refund.refund_method}</div>
                    </RefundBlock>
                    {/* <RefundBlock>
                  <RefundBlockTitle>退訂原因</RefundBlockTitle>
                  <div>退款方式：{refund.refund_method}</div>
                </RefundBlock> */}
                  </RefundBlockList>
                </>
              ) : (
                <Skeleton className="skeleton-total-box" />
              )}
            </Card>
            <MobileBottomBar>
              <Btn onClick={() => navigate(PATH_REFUND)}>確定</Btn>
            </MobileBottomBar>
          </Container>
        </Layout>
      )}
    </>
  );
};

export default RefundDetail;
